<template>
    <div class="content">
        <img src="../../assets/img/index/page-line.png" alt="" class="top-line">
        <div class="nav">
            <span style="color:rgba(51, 51, 51, .6)">首页</span>
            <span style="color:rgba(51, 51, 51, .6);margin: 0 10px;">></span>
            <span style="color:rgba(51, 51, 51, .6)">用户指南</span>
            <span style="color:rgba(51, 51, 51, .6);margin: 0 10px;">></span>
            <span>存证指南</span>
        </div>
        <div class="title">存证指南</div>
        <div class="context">
            
            <div class="leftNav">
                <div :class="current==item.channelTypeId?'nav-items nav-item':'nav-item'"  v-for="(item,index) in list" @click="current=item.channelTypeId">{{ item.channelName }}</div>
            </div>
            <div class="right">
                <div class="r-item" v-for="(item,index) in conList">
                    <div class="r-title">{{item.guideName}}</div>
                    <div class="r-info" v-html="item.guideContent"></div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import {getchannelList,getguideList} from '@/api'
  export default {
    name: "Trends",
    components: {

    },
    data() {
      return {
        current:1,
        list:[],
        conList:[]
        
      };
    },
    mounted() {
        this.getType(3)
    },
    methods: {
        getType(type){
            getchannelList({
                channelTypeId:type
            }).then(res=>{
                console.log(res)
                if(res.code==200){
                    this.list=res.data
                    this.current=this.list[0].channelTypeId
                    this.getList(this.list[0].channelTypeId)
                }
            })
        },
        getList(id){
            getguideList({
                channelId:id
            }).then(res=>{
                console.log(res)
                if(res.code==200){
                    this.conList=res.data
                }
            })
        }
    }
  };
</script>
<style scoped lang="scss">

.content{
    width: 1400px;
    margin: 0 auto;
    position: relative;
    .top-line{
        width: 100%;
        height: 85px;
        margin-top: 15px;
    }
    .nav{
        width: 100%;
        height: 85px;
        font-size: 16px;
        font-family: SimSun;
        font-weight: 400;
        color: #333333;
        position: absolute;
        top: 20px;
    }
    .title{
        font-size: 24px;
        font-family: FZCuSong-B09;
        font-weight: 600;
        color: #333333;
        text-align: center;
    }
    .context{
        width: 100%;
        margin-top: 32px;
        display: flex;
        flex-wrap: nowrap;
        .leftNav{
            width: 205px;
            // height: 560px;
            // background: url('../../assets/img/index/leftnav.png');
            // background-size: 100% 100%;
            // background-repeat: no-repeat;
            .nav-item{
                font-size: 24px;
                font-family: FZLiShu II-S06;
                font-weight: 400;
                color: #333333;
                text-align: center;
                margin-bottom: 40px;
            }
            .nav-items{
                position: relative;
            }
            .nav-items::after{
                content:" ";
                white-space: pre;
                width: 119px;
                height: 9px;
                background: url('../../assets/img/index/zp-line.png');
                background-size: 100% 100%;
                background-repeat: no-repeat;
                position: absolute;
                left: 50%;
                bottom: -15px;
                transform: translateX(-50%);
            }
        }

        .right{
            margin-left: 120px;
            .r-item{
                
                .r-title{
                    width: 129px;
                    height: 63px;
                    background-image:url('../../assets/img/index/zp-bg.png');
                    background-repeat: no-repeat;
                    background-size: 100% 100%;
                    font-size: 20px;
                    font-family: FZCuSong-B09;
                    font-weight: 400;
                    color: #FFFFFF;
                    text-align: center;
                    line-height: 43px;
                }
                .r-info{
                    font-size: 20px;
                    font-family: SimSun;
                    font-weight: 400;
                    color: #333333;
                    margin: 30px 0;
                }
            }
        }
    }
    
}

</style>
  
  